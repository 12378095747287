.loadingWrap {
  margin: 150px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.privacyContent {
  padding: 50px;
}
.useAgreeContent {
  padding: 20px;
}

.privacyWrap {
  li {
    margin-bottom: 10px;
  }
  p {
    margin-top: 5px !important;
  }
  .ListParagraph {
    margin-left: 20px !important;
  }
}
