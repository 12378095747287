.appLayout {
  height: 100vh;

  .ant-layout-has-sider {
    width: 100vw;
    min-height: 100vh;

    .ant-layout-sider {
      overflow-y: hidden;
    }

    .nestedLayout {
      .appContent {
        .tableContainer {
          overflow-x: auto;
          overflow-y: hidden;
        }
      }
    }
  }

  .layoutInfoWarp {
    padding: 30px;
    li {
      opacity: 0.2;
    }
  }
  .itemMenu {
    height: 40px !important;
  }
  .itemTitle {
    font-size: 12px;
    margin: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 10px;
  }
  .itemTitleBeta {
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    height: 64px;
    text-align: left;
    padding-top: 20px;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .ondalbotTitle {
    color: #1890ff;
  }
}

.appHeader {
  background-color: #011f3b !important;
  z-index: 999;
  .menuContainer {
    .mainText {
      @media screen and (max-width: 900px) {
        display: none !important;
      }
    }
    .logOut {
      @media screen and (max-width: 900px) {
        margin-left: auto;
      }
    }
  }
}

.appContent {
  padding: 10px;
  padding-top: 20px;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: green;
}
.ant-dropdown {
  color: green;
}

.hello {
  background-color: green;
}
